import React from 'react';
import { array } from 'prop-types';
import loadable from '@loadable/component';
import Page from 'components/page';

import { Skeleton } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpaceStationMoonAlt } from '@fortawesome/pro-solid-svg-icons';

import useStyles from './styles';

const Typography = loadable(() => import('@material-ui/core/Typography'));
const Grid = loadable(() => import('@material-ui/core/Grid'));
const LazyOverrideFooter = loadable(() => import('components/footer/override-footer'));

const SupendedTooManyFreePage = ({ navItems = [] }) => {
  const styles = useStyles();

  return (
        <Page
          title="Free Pass"
          description={'Please upgrade your subscription.'}>

              <Grid spacing={2}
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
              >
                <Grid item xs={12} sm={10}>
                  <Typography variant="h3" component="h1" color="secondary" className={styles.fourFourHeader}>
                    This is not the Loyalty Card you are looking for...
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <div className={styles.fourFourContainer}>
                    <FontAwesomeIcon icon={faSpaceStationMoonAlt} className={styles.fourFourIcon}/>
                  </div>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Typography variant="h6" component="p" className={styles.fourFourFooter}>
                    {'Free Loyalty accounts can generate one iOS and one Android Loyalty Card.  Please contact '}
                    <a
                      href="mailto:tl-support@googlegroups.com?subject=Total Loyalty Support Request"
                      target="_blank" rel="noopener noreferrer"
                      aria-label='Total Loyalty Technical Support'>
                      {'Technical Support'}
                    </a>
                    {' for extra sample Loyalty Cards.'}
                  </Typography>
                </Grid>
              </Grid>

            <LazyOverrideFooter
                navItems={navItems}
                fallback={
                  <Skeleton
                  animation="wave"
                  component="div"
                  height={100}
                  style={{
                    marginTop: -5,
                    marginRight: 10,
                    marginLeft: 10,
                  }} />
                }
                />
            </Page>
  );
};

SupendedTooManyFreePage.propTypes = { navItems: array };

export default SupendedTooManyFreePage;
