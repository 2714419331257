import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';

import { baseStyles } from 'suspended-page/styles';

export default makeStyles({
  ...baseStyles,
  landingSkeletonBanner: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
    marginTop: theme.spacing(-1.5),
  },
  fourFourIcon: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.fontSize * 16,
    color: theme.palette.secondary.light,
  },
  fourFourHeader: {
    paddingTop: 0,
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(8),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(0),
  },
  fourFourContainer: {
    paddingTop: 0,
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(5),
  },
  fourFourFooter: {
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(8),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },
});
